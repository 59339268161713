
<script setup>


</script>

<template>
    <div class="achtergrond  w-screen h-dvh lg:h-[100vh]">
      <div class="p-5 grid lg:grid-cols-2 place-items-center h-full" >
            <div class="leading-[71px] bg-clip-text text-center text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                
                <h1 class="lg:text-5xl text-2xl">COMING SOON!</h1>
                <h1 class="lg:text-5xl text-xl">New Website</h1>
                
                <p class=" leading-[40px] ">Nog even! En dan is mijn nieuwe website live! Toch in contact komen?</p>
                <div class="grid place-content-center">
    
                    <p class="leading-[35px]"><a class="justify-center flex" href="mailto:developmenttemebel@gmail.com"><i class="fa-solid fa-envelope mr-2 bg-white text-black p-2 rounded-full"></i> Developmenttemebel@gmail.com</a></p>
                    <p class="leading-[35px]"><a class="justify-center flex" href="https://developmenttemebel.com/"><i class="fa-solid fa-earth-americas bg-white text-black p-2 mr-2 rounded-full"></i>Developmenttemebel</a></p>
                    <p class="leading-[35px]"><a class="justify-center flex" href="tel:0624539865"><i class="fa-solid fa-phone bg-white text-black p-2 mr-2 rounded-full"></i> 06-24539865</a></p>
                    
                </div>
            </div>
            <div class="hidden md:block">
                <h3 class="text-2xl text-white">Wat ga ik doen?</h3>
                <ul class="text-white list-inside list-disc">
                    <p class="lg:w-[65%] leading-[34px]">Kom je niet uit de voeten met een standaard software pakket? Laten we samen de uitdaging aan gaan.</p>
                    <li>Website bouwen</li>
                    <li>Web applicaties </li>
                    <li>App applicaties</li>
                    <li>Webshop</li>
                </ul>
            </div>
      </div>

    </div>
</template>



<style scoped>
    .achtergrond {
        background-image: conic-gradient(from 115deg at  50% 50%,  #234234, #000000);
    }

</style>