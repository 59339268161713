import { createRouter, createWebHistory } from "vue-router"; 

import ComingSoon from '@/components/comingSoon.vue';
import landingPage from "@/components/landingPage.vue";




const routes = [
    {
        path: '/',
        name: 'ComingSoon',
        component: ComingSoon,
        
    },

    {
        path: '/landingpage',
        name: 'landingPage',
        component: landingPage,
        
    },

   
    


]

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
})


export default router