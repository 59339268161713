<script setup>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

let horizontalSections = gsap.utils.toArray(".horizontal-scroll");

horizontalSections.forEach((container) => {
  let sections = container.querySelectorAll(".panel");

  gsap.to(sections, {
    xPercent: -100 * (sections.length - 1),
    ease: "none",
    scrollTrigger: {
      trigger: container,
      pin: true,
      scrub: 1,
      // We nemen de container breedte om de lengte van de scroll te bepalen
      end: () => "+=" + container.offsetWidth
    }
  });
})
</script>

<template>
  <!-- Vertical scrolling content -->
  <div class="vertical-section">
    <section class="panel bg-red-300">Vertical 1</section>
    <section class="panel bg-blue-300">Vertical 2</section>
    <section class="panel bg-green-300">Vertical 3</section>
  </div>

  <!-- Horizontal scrolling content -->
  <div class="horizontal-scroll">
    <section class="panel bg-yellow-500">Horizontal 1</section>
    <section class="panel bg-orange-500">Horizontal 2</section>
    <section class="panel bg-purple-500">Horizontal 3</section>
    <section class="panel bg-green-500">Horizontal 4</section>
    <section class="panel bg-gray-500">Horizontal 5</section>
  </div>

  <!-- Meer verticale content na de horizontale scroll -->
  <div class="vertical-section">
    <section class="panel bg-red-300">Vertical 4</section>
    <section class="panel bg-blue-300">Vertical 5</section>
    <section class="panel bg-green-300">Vertical 6</section>
  </div>
</template>

<style scoped>
body {
  overscroll-behavior: none;
  height: 100vh;
  margin: 0;
}

.panel {
  min-width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.vertical-section {
  height: 100vh;
}

.horizontal-scroll {
  display: flex;
  width: 500%;
  height: 100vh;
  flex-wrap: nowrap;
}
</style>
